import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Spacer } from "ui-start";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import Layout from "../components/layout";
import { StyledPageCopy, Wrapper } from "../components/styles";
import { HeroImage } from "../components/heroImage";

const Story = () => {
  const data = useStaticQuery(graphql`
    query storyPageQuery {
      allGoogleSheet {
        edges {
          node {
            story {
              title
              copy
              heroimage
            }
          }
        }
      }
    }
  `);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.story?.[0];

  return (
    <Layout>
     <HeroImage page="story" />
      <Wrapper>
        <Spacer height={32} />
        <StyledPageCopy dangerouslySetInnerHTML={{ __html: pageData?.copy }} />
      </Wrapper>
    </Layout>
  );
};

export default Story;
